//ユーザーCSVアップロードページ
import React, { Fragment, useState, useRef, useEffect } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  Upload,
  Tag,
  theme,
  Progress,
  Modal,
} from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';
import Axios from 'axios';
import config from '../../Config/key';
const AWS = require('aws-sdk');
// const { S3 } = require('@aws-sdk/client-s3');
const Vimeo = require('vimeo').Vimeo;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function VideoUploadPage(props) {
  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [videoCategory, setVideoCategory] = useState([]); //グループ名とグループコード
  const [instructor, setInstructor] = useState([]); //グループにあるサーベイシーズン
  const [videoFileList, setVideoFileList] = useState([]);
  const [thumbnailList, setThumbnailList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [value, setValue] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { user } = props;

  // const s3 = new AWS.S3({
  //   // const s3 = new S3({
  //   accessKeyId: config.AWS_ACCESS_KEY,
  //   secretAccessKey: config.AWS_SECRET_KEY,
  //   signatureVersion: 'v4',
  //   region: config.AWS_REGION,
  // });
  // const makeS3Instance = async () => {
  //   const aksk = await Axios.get(`/api/projectKey/aksk`);
  //   console.log({ aksk });
  //   const s3 = new AWS.S3({
  //     accessKeyId: aksk.data.ak,
  //     secretAccessKey: aksk.data.sk,
  //     signatureVersion: 'v4',
  //     region: config.AWS_REGION,
  //   });
  //   return s3;
  // };
  // const s3 = makeS3Instance();

  // AWS.config.update({ region: config.AWS_REGION });
  // var roleToAssume = {
  //   RoleArn: 'arn:aws:iam::710505982292:role/EC2S3FullAccess',
  //   RoleSessionName: 'EC2S3FullAccess',
  //   DurationSeconds: 900,
  // };
  // var roleCreds;

  // // Create the STS service object
  // var sts = new AWS.STS({ apiVersion: '2011-06-15' });

  // //Assume Role
  // sts.assumeRole(roleToAssume, function (err, data) {
  //   if (err) console.log(err, err.stack);
  //   else {
  //     roleCreds = {
  //       accessKeyId: data.Credentials.AccessKeyId,
  //       secretAccessKey: data.Credentials.SecretAccessKey,
  //       sessionToken: data.Credentials.SessionToken,
  //     };
  //     stsGetCallerIdentity(roleCreds);
  //   }
  // });

  // const s3 = new AWS.S3(roleCreds);

  //Get Arn of current identity
  // function stsGetCallerIdentity(creds) {
  //   var stsParams = { credentials: creds };
  //   // Create STS service object
  //   var sts = new AWS.STS(stsParams);

  //   sts.getCallerIdentity({}, function (err, data) {
  //     if (err) {
  //       console.log(err, err.stack);
  //     } else {
  //       console.log(data.Arn);
  //     }
  //   });
  // }

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        // const [vc, inst] = await Promise.all([
        //   Axios.get('/api/videoCategory/'),
        //   Axios.get('/api/instructor/'),
        // ]);
        const vc = await Axios.get('/api/videoCategory/');
        const inst = await Axios.get('/api/instructor/');
        // console.log(vc.data.videoCategory);
        // console.log(inst.data.instructor);
        // const aksk = await Axios.get(`/api/projectKey/aksk`);
        // console.log({ aksk });
        // s3 = new AWS.S3({
        //   accessKeyId: aksk.data.ak,
        //   secretAccessKey: aksk.data.sk,
        //   signatureVersion: 'v4',
        //   region: config.AWS_REGION,
        // });
        if (vc.data.success && inst.data.success) {
          setVideoCategory(vc.data.videoCategory);
          setInstructor(inst.data.instructor);
        } else {
          throw new Error('ビデオカテゴリ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (!user.userData) return null;
    // console.log({ user });
    readDB();
    if (inputVisible) {
      inputRef.current.focus();
    }
  }, [user]);

  //Vimeo
  let client = new Vimeo(
    config.VIMEO_CLIENT_IDENTIFIER,
    config.VIMEO_CLIENT_SECRETS,
    config.VIMEO_ACCESS_TOKEN
  );

  //AntdのUpload
  const uploadVideoFile = {
    onRemove: (file) => {
      const index = videoFileList.indexOf(file);
      const newFileList = videoFileList.slice();
      newFileList.splice(index, 1);
      setVideoFileList(newFileList);
    },
    beforeUpload: (file) => {
      setVideoFileList([...videoFileList, file]);
      // console.log(file);
      return false;
    },
    videoFileList,
  };
  const uploadThumbnail = {
    onRemove: (file) => {
      const index = thumbnailList.indexOf(file);
      const newFileList = thumbnailList.slice();
      newFileList.splice(index, 1);
      setThumbnailList(newFileList);
    },
    beforeUpload: (file) => {
      setThumbnailList([...thumbnailList, file]);
      // console.log(file);
      return false;
    },
    thumbnailList,
  };

  //Vimeo
  const vimeoUpload = async (sendData) => {
    try {
      let file_name = videoFileList[0];
      client.upload(
        file_name,
        {
          name: sendData.title,
          description: sendData.video_explanation,
          folder_uri: config.VIMEO_FOLDER,
          privacy: {
            view: 'disable',
            add: false,
            comments: 'nobody',
            download: false,
            embed: 'whitelist',
          },
          embed_domains: [
            'https://re-current-survey.com',
            'https://recurrent-lms.com',
            'http://52.68.155.210:3000',
          ],
          embed: {
            buttons: {
              embed: false,
              like: false,
              hd: false,
              share: false,
              watchlater: false,
              scaling: false,
            },
            logos: { vimeo: false, custom: { active: false, sticky: false } },
            title: { name: 'hide', owner: 'hide', portrait: 'hide' },
          },
        },
        async function (uri) {
          // console.log('Your video URI is: ' + uri);
          // console.log({ accessKeyId: config.AWS_ACCESS_KEY });
          // console.log({ secretAccessKey: config.AWS_SECRET_KEY });
          sendData.url = uri;
          for (let i = 0; i < thumbnailList.length; i++) {
            let params = {
              Key: `${config.S3_ENV}${uri}/${thumbnailList[i].name}`,
              Body: thumbnailList[i],
              Bucket: config.S3_BUCKET_NAME,
              ContentType: thumbnailList[i].type,
              ACL: 'public-read',
            };
            //projectKeyからAWS ak/skを取得
            const aksk = await Axios.get(`/api/projectKey/aksk`);
            // console.log({ aksk });
            const s3 = new AWS.S3({
              accessKeyId: aksk.data.ak,
              secretAccessKey: aksk.data.sk,
              signatureVersion: 'v4',
              region: config.AWS_REGION,
            });
            const stored = await s3
              .upload(params)
              .promise()
              .catch((err) => {
                console.log({ err });
                return { success: false, error: err };
              });
            // console.log(stored);
            sendData.thumbnail.push(stored.Location);
          }
          const res = await Axios.post('/api/video', sendData);

          if (res.data.success) {
            message.success({
              content: '動画アップロード完了',
              key: 'csvupload',
              duration: 2,
            });
            // setTimeout(() => {
            //   props.history.push('/');
            // }, 1000);
            setButtonDisabled(false);
          } else {
            throw new Error('動画アップロードに失敗しました。');
          }
        },
        function (bytes_uploaded, bytes_total) {
          var percentage = ((bytes_uploaded / bytes_total) * 100).toFixed(2);
          // console.log(bytes_uploaded, bytes_total, percentage + '%');
          setUploadProgress(percentage);
        },
        function (error) {
          console.log('Failed because: ' + error);
          throw new Error(error);
        }
      );
    } catch (error) {
      console.log({ error });
      message.error({
        content: error.message,
        key: 'csvupload',
        duration: 3,
      });
      return error;
    }
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // console.log(event);
    // let userData = []; //最終分類用
    try {
      if (
        !value.instructor ||
        !value.videoName ||
        !value.videoCategory ||
        videoFileList.length === 0 ||
        thumbnailList.length === 0
      )
        throw new Error('必須項目が入力されていません');
      message.loading({
        content: '動画アップロード中...',
        key: 'csvupload',
        duration: 20,
      });
      let sendData = [];
      // console.log({ value });
      // console.log({ videoFileList });
      // console.log({ thumbnailList });
      // console.log({ tags });
      sendData = {
        title: value.videoName,
        url: '',
        tag: tags,
        category_code_id: value.videoCategory,
        // category_code: videoCategory.find(
        //   (item) => item._id === value.videoCategory
        // ).category_code,
        video_description: value.videoDescription,
        video_explanation: value.videoExplanation,
        instructor: value.instructor,
        duration_h: value.duration_h,
        duration_m: value.duration_m,
        duration_s: value.duration_s,
        thumbnail: [],
      };
      // console.log({ thumbnailList });
      setModalVisible(true);
      const res = await vimeoUpload(sendData);

      // console.log({ res });
      // const [res2, res3] = await Promise.all([
      //   Axios.post('/api/relation360', sendData),
      //   Axios.post('/api/surveyToken/ticket', sendToken),
      // ]);

      // if (res2.data.success && res3.data.success) {
      //   // if (0) {
      //   setFileData('CSVアップロードが完了しました。');
      //   message.success({
      //     content: 'アップロード完了！',
      //     key: 'csvupload',
      //     duration: 3,
      //   });
      //   setTimeout(() => {
      //     props.history.push('/');
      //   }, 1000);
      // } else {
      //   message.error({
      //     content: 'CSVアップロードに失敗しました。',
      //     key: 'csvupload',
      //     duration: 3,
      //   });
      //   throw new Error('CSVアップロードに失敗しました。');
      // }
    } catch (error) {
      console.log({ error });
      message.error({
        content: error.message,
        key: 'csvupload',
        duration: 3,
      });
    }
  };

  const onChangeVideoName = (e) => {
    setValue({ ...value, videoName: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeDuration_h = (e) => {
    setValue({ ...value, duration_h: e.currentTarget.value });
  };
  const onChangeDuration_m = (e) => {
    setValue({ ...value, duration_m: e.currentTarget.value });
  };
  const onChangeDuration_s = (e) => {
    setValue({ ...value, duration_s: e.currentTarget.value });
  };
  const onChangeExpl = (e) => {
    setValue({ ...value, videoExplanation: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeDesc = (e) => {
    setValue({ ...value, videoDescription: e.currentTarget.value });
    // console.log(value);
  };

  const onChangeVideoCategory = (v) => {
    setValue({ ...value, videoCategory: v });
  };

  const onChangeInstructor = (v) => {
    setValue({ ...value, instructor: v });
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    // console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags.map(forMap);
  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  const handleModalOk = async (e) => {
    //setLoading(true);
    setModalVisible(false);
    setButtonDisabled(true);
    setUploadProgress(0);
    props.history.push('/videoupload');
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>動画アップロード</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <div>
            <br />
            <Text strong>■ 動画ファイル選択：１個（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Upload {...uploadVideoFile}>
                <Button icon={<UploadOutlined />}>動画ファイル選択</Button>
              </Upload>
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 動画名（必須）</Text>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
            ></div>
            <Input
              placeholder="動画名（必須）"
              name="mt_name"
              allowClear
              onChange={onChangeVideoName}
              value={value['videoName']}
            />
          </div>
          <div>
            <br />
            <Text strong>■ 再生時間（必須）</Text>
            <br />
            <Input
              style={{ marginLeft: 0, marginRight: 10, width: 80 }}
              placeholder="時間"
              name="duration_h"
              allowClear
              onChange={onChangeDuration_h}
              value={value['duration_h']}
            />
            時間　
            <Input
              style={{ marginLeft: 0, marginRight: 10, width: 80 }}
              placeholder="分"
              name="duration_m"
              allowClear
              onChange={onChangeDuration_m}
              value={value['duration_m']}
            />
            分　
            <Input
              style={{ marginLeft: 0, marginRight: 10, width: 80 }}
              placeholder="秒"
              name="duration_m"
              allowClear
              onChange={onChangeDuration_s}
              value={value['duration_s']}
            />
            秒
          </div>
          <div>
            <br />
            <Text strong>■ 動画カテゴリ選択（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="動画カテゴリ選択"
                optionFilterProp="items"
                onChange={onChangeVideoCategory}
                value={value['videoCategory']}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {videoCategory.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.category_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 講師選択（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="講師選択"
                optionFilterProp="items"
                onChange={onChangeInstructor}
                value={value['instructor']}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {instructor.map((element) => {
                  return (
                    <Option value={element._id} key={element._id}>
                      {element.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 動画タイトル：ユーザ向け（表示用）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextArea
                rows={3}
                onChange={onChangeExpl}
                value={value['videoExplanation']}
              />
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ 動画説明：管理向け</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextArea
                rows={3}
                onChange={onChangeDesc}
                value={value['videoDescription']}
              />
            </div>
          </div>
          <div>
            <br />
            <Text strong>■ サムネール選択：複数可</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Upload {...uploadThumbnail}>
                <Button icon={<UploadOutlined />}>
                  サムネールファイル選択
                </Button>
              </Upload>
            </div>
          </div>

          <div>
            <br />
            <Text strong>■ Tag記入</Text>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
            <div
              style={{
                marginBottom: 16,
              }}
            >
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: 'from',
                  duration: 100,
                }}
                onEnd={(e) => {
                  if (e.type === 'appear' || e.type === 'enter') {
                    e.target.style = 'display: inline-block';
                  }
                }}
                leave={{
                  opacity: 0,
                  width: 0,
                  scale: 0,
                  duration: 200,
                }}
                appear={false}
              >
                {tagChild}
              </TweenOneGroup>
            </div>
            <div>
              {inputVisible ? (
                <Input
                  ref={inputRef}
                  type="text"
                  size="small"
                  style={{
                    width: 78,
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Tag onClick={showInput} style={tagPlusStyle}>
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </div>
            {/* </div> */}
          </div>

          <Button
            type="primary"
            onClick={onSubmit}
            disabled={
              videoFileList.length === 0 ||
              value['videoName'] === '' ||
              value['videoCategory'] === ''
            }
            loading={uploading}
            size="large"
            style={{
              marginTop: 16,
            }}
          >
            {uploading ? 'アップロード中' : 'アップロード開始'}
          </Button>
        </Form>
        <Modal
          title="動画アップロード"
          open={modalVisible}
          onOk={handleModalOk}
          footer={[
            <Button
              key="submit"
              type="primary"
              disabled={buttonDisabled}
              onClick={handleModalOk}
            >
              OK
            </Button>,
          ]}
        >
          <div
            style={{
              height: '12em',
              position: 'relative',
            }}
          >
            <div
              style={{
                margin: '0',
                height: '10em',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {uploadProgress < 100 ? 'アップロード中...' : 'アップロード完了'}
              <br />
              <br />
              <Progress type="circle" percent={uploadProgress} />
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
}

export default VideoUploadPage;
