import React, { Fragment, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Form,
  message,
  Input,
  Select,
  DatePicker,
  ConfigProvider,
  Checkbox,
  Col,
  Row,
} from 'antd';
// import 'moment/locale/ja';
import locale from 'antd/es/locale/ja_JP';
import dayjs from 'dayjs';
// import 'dayjs/locale/ja';
// import type { DatePickerProps } from 'antd';
//90101 = [0,0,0,0,0]
//90301 = []
//90501 = [0,0]
import Axios from 'axios';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
// let checkTag = <></>;

//yyyy-mm-dd 00:00:00
const today_ymd = () => {
  let date = new Date();
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2) +
    ' 00:00:00'
  );
};

const MakeSeasonPage = (props) => {
  const [value, setValue] = useState({
    output_360_date: today_ymd(),
    survey_start_date: today_ymd(),
    survey_end_date: today_ymd(),
    training_start_date: today_ymd(),
    training_end_date: today_ymd(),
  });
  const [mode, setMode] = useState('new');
  const [groupInfo, setGroupInfo] = useState([]);
  const [surveyInfo, setSurveyInfo] = useState([]);
  // const [coursePartViewString, setCoursePartViewString] = useState('');
  const sInfo = props.location.state;
  const { user } = props;
  // console.log({ sInfo });
  // let coursePartViewString = '';

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        const [group, survey] = await Promise.all([
          Axios.get('/api/group/'),
          Axios.get('/api/survey/'),
        ]);
        // console.log(group.data.groups);
        // console.log(survey.data.survey);
        if (group.data.success && survey.data.success) {
          setGroupInfo(group.data.groups);
          setSurveyInfo(survey.data.survey);
        } else {
          throw new Error('グループ取得に失敗しました。');
        }
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user.userData) return null;
    readDB();
    if (sInfo._id) {
      // console.log({ sInfo });
      setMode('edit');
      setValue({
        _id: sInfo._id,
        season_name: sInfo.season_name,
        group_code: sInfo.group_code,
        group_id: sInfo.group_id,
        season_description: sInfo.season_description,
        t_name: sInfo.t_name,
        survey_code: sInfo.survey_code,
        survey_id: sInfo.survey_id,
        survey_start_date: sInfo.survey_start_date,
        survey_end_date: sInfo.survey_end_date,
        training_start_date: sInfo.training_start_date,
        training_end_date: sInfo.training_end_date,
        output_360_type: sInfo.output_360_type,
        output_360_date: sInfo.output_360_date,
        e3_option: sInfo.e3_option,
        survey_name: sInfo.survey_name,
        name_360: sInfo.name_360,
        course_part_view: sInfo.course_part_view,
        course_part_view_string: arrayToString(sInfo.course_part_view),
      });
    } else {
      setValue({ ...value, active: true });
    }
  }, [user]);

  const arrayToString = (arr) => {
    let str = '';
    for (let i = 0; i < arr.length; i++) {
      str = str + String(arr[i]);
    }
    return str;
  };

  const onChangeSeasonName = (e) => {
    setValue({ ...value, season_name: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeSeasonDescription = (e) => {
    setValue({ ...value, season_description: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeTName = (e) => {
    setValue({ ...value, t_name: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeName360 = (e) => {
    setValue({ ...value, name_360: e.currentTarget.value });
    // console.log(value);
  };
  const onChangeGroup = (v) => {
    setValue({
      ...value,
      group_code: v,
      group_id: groupInfo.find((item) => item.group_code === v)._id,
    });
    // console.log(value);
  };
  const onChangeSurvey = (v) => {
    if (v === '40010') {
      setValue({
        ...value,
        survey_code: v,
        survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
        survey_name: surveyInfo.find((item) => item.survey_code === v)
          .survey_name,
        course_part_view: [],
      });
      // checkTag = <></>;
    } else if (v === '90101') {
      setValue({
        ...value,
        survey_code: v,
        survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
        survey_name: surveyInfo.find((item) => item.survey_code === v)
          .survey_name,
      });
    } else if (v === '90501') {
      setValue({
        ...value,
        survey_code: v,
        survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
        survey_name: surveyInfo.find((item) => item.survey_code === v)
          .survey_name,
      });
    } else {
      setValue({
        ...value,
        survey_code: v,
        survey_id: surveyInfo.find((item) => item.survey_code === v)._id,
        survey_name: surveyInfo.find((item) => item.survey_code === v)
          .survey_name,
        output_360_type: null,
        output_360_date: null,
        e3_option: null,
        course_part_view: [],
      });
    }
    // console.log(value);
  };

  const onChangeCheck = (e) => {
    const valueArr = e.currentTarget.value.split('');
    let numArr = [];
    for (let i = 0; i < valueArr.length; i++) {
      numArr.push(Number(valueArr[i]));
    }
    setValue({
      ...value,
      course_part_view: numArr,
      course_part_view_string: e.currentTarget.value,
    });
  };

  const onChangeOpenPdf = (v) => {
    // setOpenPdf360(v);
    setValue({
      ...value,
      output_360_type: v,
      // output_360_date: null,
    });
    // console.log(value);
  };

  const onChangeOption40010 = (v) => {
    // setOpenPdf360(v);
    setValue({
      ...value,
      e3_option: v,
    });
    // console.log(value);
  };
  //研修日程をStart→Endの形に変更 20221215
  const onChangeTrainingPriod = (date, dateString) => {
    // console.log(date, dateString);
    setValue({
      ...value,
      training_start_date: dateString[0] + ' 00:00:00',
      training_end_date: dateString[1] + ' 23:59:59',
    });
  };
  //研修日程をStart→Endの形に変更 20221215
  const onChangeSurveyPriod = (date, dateString) => {
    // console.log(date, dateString);
    setValue({
      ...value,
      survey_start_date: dateString[0] + ' 00:00:00',
      survey_end_date: dateString[1] + ' 23:59:59',
    });
  };
  // const onChangeEndDate = (date, dateString) => {
  //   // console.log(date, dateString);
  //   setValue({ ...value, survey_end_date: dateString + ' 23:59:59' });
  // };
  const onChangeopenPdf360Date = (date, dateString) => {
    // console.log(date, dateString);
    setValue({ ...value, output_360_date: dateString + ' 00:00:00' });
  };

  const onSubmit = async () => {
    try {
      let res = {};
      // console.log({ mode });
      if (mode === 'edit') {
        // console.log({ value });
        res = await Axios.patch('/api/surveySeason', value); //APIの追加が必要
      } else {
        // console.log({ value });
        res = await Axios.post('/api/surveySeason', value);
      }
      // console.log({ res });
      if (res.data.success) {
        message.success({
          content: 'シーズン登録完了！',
          key: 'submit',
          duration: 2,
        });
        setTimeout(() => {
          props.history.push('/seasonlist');
        }, 1000);
      } else {
        throw new Error('シーズン登録に失敗しました。');
      }
    } catch (error) {
      message.error({
        content: 'シーズン登録に失敗しました。',
        key: 'submit',
        duration: 2,
      });
      console.log({ error });
    }
  };

  const onCancel = async () => {
    props.history.push('/seasonlist');
  };

  return (
    <ConfigProvider locale={locale}>
      <Fragment>
        <div style={{ maxWidth: '600px', margin: '2rem auto' }}>
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <Title level={2}>サーベイ・シーズン作成</Title>
          </div>
          <Form onSubmit={onSubmit}>
            <br />
            <div>
              <Text strong>■ シーズン名：例）2022 人事評価力診断 1回</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <Input
              placeholder="シーズン名（必須）"
              name="season_name"
              allowClear
              onChange={onChangeSeasonName}
              value={value['season_name']}
            />
            <br />
            <br />
            <div>
              <Text strong>■ シーズン説明：全ての情報を網羅</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <Input
              placeholder="シーズン説明（必須）"
              name="season_description"
              allowClear
              onChange={onChangeSeasonDescription}
              value={value['season_description']}
            />
            <br />
            <br />
            <div>
              <Text strong>■ 研修名：例）新任課長代理研修</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <Input
              placeholder="研修名（必須）"
              name="t_name"
              allowClear
              onChange={onChangeTName}
              value={value['t_name']}
            />
            <br />
            <br />
            <div>
              <Text strong>■ 企業選択（必須）</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <Select
              showSearch
              style={{
                width: 600,
              }}
              placeholder="企業選択"
              optionFilterProp="items"
              onChange={onChangeGroup}
              value={value['group_code']}
              disabled={mode === 'edit'}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {groupInfo.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.group_code} key={element._id}>
                    {element.group_name}
                  </Option>
                );
              })}
            </Select>
            <br />
            <br />
            <div>
              <Text strong>■ サーベイ選択</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <Select
              showSearch
              style={{
                width: 600,
              }}
              placeholder="サーベイ選択"
              optionFilterProp="items"
              onChange={onChangeSurvey}
              value={value['survey_code']}
              filterOption={(input, option) =>
                option.props.items.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              {surveyInfo.map((element) => {
                // console.log({ element });
                return (
                  <Option value={element.survey_code} key={element._id}>
                    {element.survey_name}
                  </Option>
                );
              })}
            </Select>
            {value.survey_code === '90101' && (
              <Fragment>
                <br />
                <br />
                <Text strong>■ 表示領域選択記号（例：0111）</Text>
                <br />
                <br />
                <Input
                  placeholder="コース構成"
                  name="course_part_view_string"
                  allowClear
                  onChange={onChangeCheck}
                  value={value['course_part_view_string']}
                />
              </Fragment>
            )}
            {value.survey_code === '90501' && (
              <Fragment>
                <br />
                <br />
                <Text strong>■ 表示領域選択記号（例：11）</Text>
                <br />
                <br />
                <Input
                  placeholder="コース構成"
                  name="course_part_view_string"
                  allowClear
                  onChange={onChangeCheck}
                  value={value['course_part_view_string']}
                />
              </Fragment>
            )}
            <br />
            <br />
            {value.survey_code === '30010' && (
              <Fragment>
                <Select
                  showSearch
                  style={{
                    width: 400,
                  }}
                  placeholder="オプション選択"
                  optionFilterProp="items"
                  onChange={onChangeOption40010}
                  value={value.e3_option}
                  filterOption={(input, option) =>
                    option.props.items
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0} key={0}>
                    組織診断のみ
                  </Option>
                  <Option value={1} key={1}>
                    パーソナル診断のみ
                  </Option>
                  <Option value={2} key={2}>
                    組織診断＋パーソナル診断
                  </Option>
                  )
                </Select>
                <br />
                <br />
              </Fragment>
            )}
            {value.survey_code === '40010' && (
              <Fragment>
                <Select
                  showSearch
                  style={{
                    width: 400,
                  }}
                  placeholder="公開選択"
                  optionFilterProp="items"
                  onChange={onChangeOpenPdf}
                  value={value.output_360_type}
                  filterOption={(input, option) =>
                    option.props.items
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={0} key={0}>
                    非公開（管理者のみ公開）
                  </Option>
                  <Option value={1} key={1}>
                    受検直後公開
                  </Option>
                  <Option value={2} key={2}>
                    日付指定公開
                  </Option>
                  )
                </Select>
                <br />
                <br />
                <div>
                  <Text strong>■ 360度診断名：例）360度サーベイ</Text>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  ></div>
                </div>
                <Input
                  placeholder="360度診断"
                  name="name_360"
                  allowClear
                  onChange={onChangeName360}
                  value={value['name_360']}
                />
                <br />
                <br />
              </Fragment>
            )}
            {value.survey_code === '40010' && value.output_360_type === 2 && (
              <Fragment>
                <DatePicker
                  onChange={onChangeopenPdf360Date}
                  locale={locale}
                  value={dayjs(value.output_360_date)}
                />
                →０時から公開
                <br />
                <br />
              </Fragment>
            )}
            <div>
              <Text strong>■ 研修日程</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <RangePicker
              onChange={onChangeTrainingPriod}
              locale={locale}
              value={[
                dayjs(value.training_start_date),
                dayjs(value.training_end_date),
              ]}
            />
            <br />
            <br />
            <div>
              <Text strong>■ サーベイ回答日程</Text>
              <div
                style={{ display: 'flex', justifyContent: 'space-between' }}
              ></div>
            </div>
            <RangePicker
              onChange={onChangeSurveyPriod}
              locale={locale}
              value={[
                dayjs(value.survey_start_date),
                dayjs(value.survey_end_date),
              ]}
            />
            <br />
            <br />
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              disabled={false}
            >
              登録
            </Button>
            　
            <Button
              type="primary"
              size="large"
              onClick={onCancel}
              disabled={false}
            >
              キャンセル
            </Button>
          </Form>
        </div>
      </Fragment>
    </ConfigProvider>
  );
};

export default MakeSeasonPage;
